@import '../../variables.scss';

.list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-bottom: 5px;
}

.item {
  display: flex;
  // height: 56px;
  align-items: center;
  border: 1px solid $gray-10;
  background: $blue-5;
  padding: 10px;
  border-radius: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: calc(25% - 5px);
  flex-grow: 1;
  margin-right: 5px;
  margin-bottom: 5px;
  min-width: 250px;
  transition: background-color 0.1s $cubic;

  &:hover {
    background: $white;
  }

  @include for-size(tablet-portrait-down) {
    flex-basis: 50%;
  }
}

.left {
  overflow: hidden;
  width: 100%;
  // flex-basis: calc(100% - 20px)
}

.primary {
  // display: flex;
  line-height: 1.1;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  color: $black;
  // flex-grow: 0;

  &:hover {
    color: $blue-100;
  }
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  flex-shrink: 0;
}

.secondary {
  color: $gray-60;
  font-size: 12px;
  line-height: 1.1;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 14px;
  margin-left: auto;

  &:hover {
    color: $red-50;
  }

  .closeIcon {
    height: 15px;
    width: 15px;
  }
}

.addItem {
  display: flex;
  padding: 5px;
  // margin-right: 5px;
  // margin-bottom: 5px;
  min-width: 85px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $blue-100;

  &:hover {
    text-decoration: underline;
  }
}

.row {
  display: flex;
  margin-bottom: 5px;
  flex-wrap: wrap;

  &.linkVisible {
    flex-direction: column-reverse;
    align-items: flex-start;

    .field {
      width: 100%;
      display: flex;

      .fieldInput {
        flex-grow: 1;
      }

      .fieldButton {
        margin-left: auto;
      }
    }
  }
}

.field {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  border-radius: 3px;
  background: $white;
  border: 1px solid $gray-20;
  flex-grow: 1;
  display: flex;
  height: 45px;
  overflow: hidden;
  padding: 5px 5px 5px 10px;
  position: relative;
}

.fieldButton {
  margin-left: 0;
  padding: 5px 10px !important;
  min-height: 30px !important;
}

.fieldInput {
  border: 0;
  outline: 0;
  padding: 0;
  flex: 1;
}

.moreActionArea {
  display: flex;
  width: 100%;
}

.loadMore {
  border: 0;
  outline: none;
  background: none;
  text-decoration: underline;
  color: $blue-80;
  margin: 0 auto;
  pointer-events: auto;
  font-size: 14px;
  cursor: pointer;
}

.hasError {
  .label {
    position: absolute;
    top: 0;
    left: 10px;
    color: $red-50;

    & ~ .fieldInput {
      padding-top: 15px;
    }
  }
}

.label {
  font-size: $size-1;
  font-weight: 600;
  line-height: $size-6;
  pointer-events: none;
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
