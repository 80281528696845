@import '../variables.scss';

.overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: $z-background;
  background-color: hsla(210, 11%, 15%, 0.3);
}

.transition {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.enterFrom {
  opacity: 0;
}

.enterTo {
  opacity: 1;
}

.leaveFrom {
  opacity: 1;
  transform: scale(1);
}

.leaveTo {
  opacity: 0;
  transform: scale(0.95);
}

.container {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  // overflow-y: auto;

  display: flex;
  // align-items: center;
  // justify-content: center;
  // z-index: $z-headless-dialog;
  // Tim: I changed this because it was causing problems with evergreen dropdowns
  // See PortfolioEdit
  // z-index: $z-portfolio-viewer;

  // Paul: since were not using evergreen dropdown I updated this
  // because current index is causing some issues in frontend nav and header
  z-index: $z-dialog;
}

.panel {
  box-sizing: border-box;
  background-color: $white;
  z-index: $z-dialog;
  border-radius: $border-radius;
  max-height: 100%;
  margin: auto;
  // overflow: auto; // Paul: I removed this because it was causing problems with select dropdown, date and time

  // Tim: Added this because I wanted to prevent the dialog to go out of bounds on mobile
  max-width: 100%;

  @include for-size(tablet-portrait-up) {
    max-height: 80%;
  }

  @include for-size(tablet-landscape-up) {
    max-width: 90%;
  }

  @include for-size(desktop-up) {
    max-width: 70%;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: $size-5;
  font-weight: $fw-2;
}
.description {
  margin-bottom: 16px;
}

.body {
  position: relative;
  padding: 20px;
  min-width: 350px;
}

.actions {
  background-color: $gray-7;
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  width: 100%;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.close {
  background: none;
  border: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 2px 4px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: $gray-7;
  }

  &:focus {
    outline: none;
  }
}
